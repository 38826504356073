<template>
  <div class="popupbtn_warp">
    <div class="content_zone">
      <slot></slot>
    </div>
    <div class="buttonzone">
      <van-button class="buttonzone_btn" type="default" @click="onReset">
        重置
      </van-button>
      <van-button class="buttonzone_btn" type="primary" @click="onConfirm">
        确定
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant'
export default {
  name: 'PopupBtn',
  components: {
    [Button.name]: Button
  },
  methods: {
    onReset: function(e) {
      this.$emit('onPpReset', e)
    },
    onConfirm: function(e) {
      this.$emit('onPpConfirm', e)
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.popupbtn_warp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .content_zone {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .buttonzone {
    display: flex;
    justify-content: space-between;
    &_btn {
      flex: 1;
    }
  }
}
</style>
