<template>
  <div class="item_list">
    <van-nav-bar
      title="销量排行"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="navBack"
    />
    <div class="item_list_poster">
      <van-image class="item_list_poster_img" :src="poster" />
      <div class="item_list_poster_title">
        销量排行榜
      </div>
      <div class="item_list_poster_time">
        <span>{{ dayonec }}-{{ daytwoc }}</span>
      </div>
    </div>
    <van-tabs
      v-model="tabActive"
      sticky
      offset-top="12.267vw"
      class="item_list_tab"
      :before-change="beforeChange"
      :class="filterActive ? 'cfilteractive' : ''"
      @disabled="toggleFilterModal(true)"
      @change="ontabChange"
    >
      <van-tab
        v-for="tab in tabsItem"
        :key="tab.type"
        :title="tab.name"
        :disabled="tab.sort === false"
        class="fix-tabheight"
      >
        <template v-if="tab.sort === false" #title>
          <van-icon
            class="item_list_tab_icon"
            name="filter"
            class-prefix="my-icon"
          />
        </template>
        <!-- <van-list
          :ref="'tabScrolls' + tabIndex"
          v-model="tab.loading"
          :finished="tab.finished"
          finished-text="没有更多了"
          :error.sync="tab.error"
          error-text="请求失败，点击重新加载"
          offset="100"
          @load="onListLoad(tabIndex, tab.sort)"
        >
          <item-card-person
            v-for="item in tab.items"
            :key="item"
            class="class_item_card"
          />
        </van-list> -->
      </van-tab>
    </van-tabs>
    <div class="fixheight">
      <van-pull-refresh
        v-model="isLoading"
        class="fixheight"
        @refresh="onRefresh(tabActivenow)"
      >
        <van-list
          :ref="'tabScrolls'"
          v-model="dispitem.loading"
          :finished="dispitem.finished"
          finished-text="没有更多了"
          :error.sync="dispitem.error"
          error-text="请求失败，点击重新加载"
          offset="100"
          @load="onListLoad(tabActivenow)"
        >
          <item-card-person
            v-for="(item, index) in dispitem.items"
            :key="index"
            :rank="index + 1"
            :name="item.name === null ? item.nickname : item.name"
            :time="formTime(new Date(item.reg_time))"
            :amount="item.sum"
            :avatar="item.head_pic"
            :type="item.type_name"
            class="class_item_card"
          />
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup
      v-model="filterItemShow"
      class="filterItem"
      position="right"
      :style="{ height: '100%', width: '70%' }"
    >
      <popup-btn @onPpReset="onPpReset" @onPpConfirm="onPpConfirm">
        <van-cell-group title="开始时间">
          <van-cell
            is-link
            title="选择时间"
            :value="selectdateBeginS"
            @click="changeTimeStart"
          />
        </van-cell-group>
        <van-cell-group title="结束时间">
          <van-cell
            is-link
            title="选择时间"
            :value="selectdateEndS"
            @click="changeTimeEnd"
          />
        </van-cell-group>
      </popup-btn>
    </van-popup>
    <van-popup
      v-model="showdatepicker"
      :style="{ width: '70%' }"
      class="class_datetimepicker"
    >
      <van-datetime-picker
        v-model="selecttDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="dtpickercancel"
        @confirm="dtpickerconfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  List,
  Search,
  Cell,
  CellGroup,
  Tab,
  Tabs,
  Popup,
  NavBar,
  Image,
  DropdownMenu,
  PullRefresh,
  DropdownItem,
  DatetimePicker
} from 'vant'
import itemCardPerson from '../../../components/item-card-person'
import poster from '@/assets/rankposter.png'
import PopupBtn from '@/components/PopupBtn'
import { format, DATE_FMTF, DATE_FMTZ } from '@/utils/date'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
// eslint-disable-next-line no-unused-vars
import { loadSalesRank } from '@/api/home'
export default {
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [PullRefresh.name]: PullRefresh,
    [itemCardPerson.name]: itemCardPerson,
    [PopupBtn.name]: PopupBtn,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [DatetimePicker.name]: DatetimePicker
  },
  mixins: [asyncwait, handleNetError],
  data() {
    return {
      transitionName: 'van-slide-right',
      minDate: new Date(2020, 1, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      selectdateBegin: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000),
      selectdateEnd: new Date(),
      selecttDate: new Date(),
      tabActive: 0,
      tabActivenow: 0,
      isLoading: false,
      sort: this.sortVal,
      paramlist: ['', 'week', 'month', 'year'],
      dayzero: '2020年02月01日',
      dayone: '2020-02-01',
      daytwo: format(new Date(), DATE_FMTF),
      dispitem: {
        sort: '',
        param: '',
        loading: false,
        finished: false,
        error: false,
        pageIndex: 1,
        items: []
      },
      tabsItem: [
        {
          name: '全部排行',
          sort: '',
          items: []
        },
        {
          name: '周',
          sort: 'week',
          items: []
        },
        {
          name: '月',
          sort: 'month',
          items: []
        },
        {
          name: '年',
          sort: 'year',
          items: []
        },
        { name: '筛选', sort: false, items: [] }
      ],
      filterItemShow: false,
      filterActive: false,
      showdatepicker: false,
      selectdateBeginS: '',
      selectdateEndS: '',
      datepickermode: 0,
      poster
    }
  },
  computed: {
    sortVal() {
      const { tabActive: i } = this
      return this.tabsItem[i].sort
    },
    dayonec() {
      if (this.dayone === '') return this.dayzero
      return format(new Date(this.dayone), DATE_FMTZ)
    },
    daytwoc() {
      if (this.dayone === '') return format(new Date(), DATE_FMTZ)
      return format(new Date(this.daytwo), DATE_FMTZ)
    }
  },
  created() {},
  methods: {
    toggleFilterModal(status) {
      this.filterItemShow = status
      this.selectdateBeginS = format(this.selectdateBegin, DATE_FMTF)
      this.selectdateEndS = format(this.selectdateEnd, DATE_FMTF)
    },
    formTime(v) {
      format(v, DATE_FMTF)
    },
    dtformtran(v) {
      return /^(\d{4})-(\d{2})-(\d{2})/.exec(v)
    },
    navBack() {
      this.$router.go(-1)
    },
    onPpReset() {
      console.log('onPpReset')
      this.filterItemShow = false
      this.filterActive = false
      this.onRefresh()
    },
    onPpConfirm() {
      console.log('onPpConfirm')
      console.log(`${this.selectdateBeginS} -- ${this.selectdateEndS}`)
      this.filterItemShow = false
      this.filterActive = true
      this.tabActive = 0
      this.onRefresh()
    },
    changeTimeStart() {
      console.log('changeTimeStart')
      this.selecttDate = this.selectdateBegin
      this.datepickermode = 0
      this.showPopup()
    },
    changeTimeEnd() {
      console.log('changeTimeEnd')
      this.selecttDate = this.selectdateEnd
      this.datepickermode = 1
      this.showPopup()
    },
    showPopup() {
      this.showdatepicker = true
    },
    dtpickercancel() {
      this.showdatepicker = false
    },
    dtpickerconfirm(dt) {
      console.log('dtpickerconfirm')
      console.log(dt)
      this.showdatepicker = false
      if (this.datepickermode === 0) {
        this.selectdateBegin = this.selecttDate
        this.selectdateBeginS = format(this.selectdateBegin, DATE_FMTF)
      } else {
        this.selectdateEnd = this.selecttDate
        this.selectdateEndS = format(this.selectdateEnd, DATE_FMTF)
      }
    },
    // ontabClick(e) {
    ontabChange(e) {
      // if (this.tabActivenow === this.tabActive) return
      // this.$refs[`tabScrolls${e}`].check
      this.$nextTick(() => {
        // console.log(this.$refs[`tabScrolls${e}`][0])
        this.dispitem.param = this.paramlist[e]
        this.filterActive = false
        this.isLoading = true
        this.onRefresh(e)
        // this.$refs[`tabScrolls${e}`][0].check()
      })
    },
    // onListLoad(tabIndex) {
    //   console.log(`callload${tabIndex}`)
    //   setTimeout(() => {
    //     for (let i = 0; i < 10; i++) {
    //       this.tabsItem[tabIndex].items.push(
    //         this.tabsItem[tabIndex].items.length + 1
    //       )
    //     }

    //     // 加载状态结束
    //     this.tabsItem[tabIndex].loading = false
    //     console.log(this.tabsItem[tabIndex].items.length)
    //     // 数据全部加载完成
    //     if (this.tabsItem[tabIndex].items.length >= 40) {
    //       this.tabsItem[tabIndex].finished = true
    //     }
    //   }, 1000)
    // },
    async onRefresh(e) {
      // 清空数据
      // await this.initData()
      this.isLoading = false
      this.dispitem.items = []
      this.dispitem.finished = false
      this.refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.dispitem.pageIndex = 1
      this.dispitem.loading = true
      await this.onListLoad(e)
      this.tabActivenow = this.tabActive
      // this.isLoading = false
    },
    beforeChange() {
      console.log('beforeChange')
      // 返回 false 表示阻止此次切换
      // if (this.isLoading === true) return false
      if (this.dispitem.loading === true) return false
      return true
    },
    async onListLoad(tabIndex) {
      console.log(`callload${tabIndex}`)
      await this.asyncWait(300)
      let param = ''
      try {
        if (this.filterActive) {
          param = {
            stime: this.selectdateBeginS,
            etime: this.selectdateEndS
          }
        } else {
          param = { time: this.dispitem.param }
        }
        const res = await loadSalesRank({
          pageIndex: this.dispitem.pageIndex,
          pageSize: 10,
          ...param
        })
        if (res.status !== 1) throw res.msg
        this.dispitem.pageIndex++
        this.dispitem.items = [...this.dispitem.items, ...res.data]
        this.dispitem.loading = false
        if (res.data.length < 10) {
          this.dispitem.finished = true
        }
        this.dayone = res.startDate
        this.daytwo = res.endDate
        console.log(res)
        console.log('loadSalesRank cp')
      } catch (err) {
        this.handleNetError(err)
        this.dispitem.loading = false
        this.dispitem.error = true
        console.log(err)
      }
    }
  }
}
</script>

<style lang="less">
@import '@/style/global.less';
.item_list {
  height: 100%;
  &_tab {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
  }
}
.item_list_poster {
  position: relative;
  height: 128px;
  &_img {
    width: 100%;
  }
  &_title {
    position: absolute;
    top: 39px;
    left: 18px;
    z-index: 10;
    height: 40px;
    font-family: FZZhengHeiS-EB-GB;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: #ffffff;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }
  &_time {
    position: absolute;
    top: 80px;
    left: 18px;
    z-index: 10;
    height: 16px;
    font-family: Microsoft YaHei;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
    opacity: 1;
  }
}
.fixheight {
  height: calc(100vh - 44px - 46px - 128px);
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #f1f3f5;
}

.item_list_tab_icon {
  font-size: 14px;
}

// .fix-tabheight {
//   height: 44px;
// }

.item_list .van-tab--disabled {
  color: #000000;
}

.item_list .cfilteractive .van-tab--disabled {
  color: red;
}
.class_datetimepicker {
  border-radius: 5px;
}
</style>
